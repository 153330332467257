import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "./../global/store";
import ProtectedRoute from "./ProtectedRoute";
import AdminDashboard from "./../components/admin/Dashboard";
// import Signup from "./../components/admin/Signup";
import Login from "./../components/admin/Login";
import ScrollToTop from "./scrollToTop";

const App = () => {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route
            path="/signup"
            exact
            render={(props) => <Signup {...props}></Signup>}
          ></Route> */}
          <Route
            path="/login"
            exact
            render={(props) => <Login overview {...props}></Login>}
          ></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/overview"
            exact
            render={(props) => (
              <AdminDashboard overview {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses"
            exact
            render={(props) => (
              <AdminDashboard normalCourses {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/course-subscribers/:id"
            exact
            render={(props) => (
              <AdminDashboard courseSubscribers {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses/add-course/:type"
            exact
            render={(props) => (
              <AdminDashboard addCourse {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-course/:type/:id"
            exact
            render={(props) => (
              <AdminDashboard editCourse {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses"
            exact
            render={(props) => (
              <AdminDashboard followCourses {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses/add-course"
            exact
            render={(props) => (
              <AdminDashboard addCourseFollow {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/follow-courses/edit-course/:id"
            exact
            render={(props) => (
              <AdminDashboard editCourseFollow {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders"
            exact
            render={(props) => (
              <AdminDashboard coursesOrders {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders/:id"
            exact
            render={(props) => (
              <AdminDashboard courseOrderDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers"
            exact
            render={(props) => (
              <AdminDashboard subscribers {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers/:courseId"
            exact
            render={(props) => (
              <AdminDashboard subscribersList {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/subscribers/:courseId/:id"
            exact
            render={(props) => (
              <AdminDashboard subscribersDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/group-coaching"
            exact
            render={(props) => (
              <AdminDashboard groupCoaching {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/group-coaching/:courseId"
            exact
            render={(props) => (
              <AdminDashboard groupCoachingDetail {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/group-courses/:courseId/:userId"
            exact
            render={(props) => (
              <AdminDashboard
                groupCoachingUserDetail
                {...props}
              ></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/dates"
            exact
            render={(props) => (
              <AdminDashboard dates {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/sessions"
            exact
            render={(props) => (
              <AdminDashboard sessions {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/blogs"
            exact
            render={(props) => (
              <AdminDashboard blogs {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/add-blog"
            exact
            render={(props) => (
              <AdminDashboard addBlog {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-blog/:id"
            exact
            render={(props) => (
              <AdminDashboard editBlog {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center"
            exact
            render={(props) => (
              <AdminDashboard mediaCenter {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center/:type"
            exact
            render={(props) => (
              <AdminDashboard mediaCenterType {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/settings"
            exact
            render={(props) => (
              <AdminDashboard settings {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/users"
            exact
            render={(props) => (
              <AdminDashboard Users {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/user/:id"
            exact
            render={(props) => (
              <AdminDashboard User {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/sayings"
            exact
            render={(props) => (
              <AdminDashboard sayings {...props}></AdminDashboard>
            )}
          ></ProtectedRoute>
          <Redirect to="/admin/overview"></Redirect>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
