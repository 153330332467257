import React, { useState } from "react";
import Modal from "react-modal";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "../../../../../global/domain";
import { IconContext } from "react-icons";
import LoaderModal from "../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { deleteSessionFromGroupCoaching } from "../../../../../services/followSession";

const SessionDetailModal = ({ isOpen, closing, currentSession, mutate }) => {
  const screenWidth = useWindowWidth();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}>
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center x-sm:text-xs md:text-base 2xl:text-3xl">
        <h3 className="text-reghda-blue font-semibold">تفاصيل الجلسة</h3>
        <div className="w-full">
          <div className="w-full flex flex-col space-y-4 2xl:space-y-8 text-reghda-black x-sm:mt-6 sm:mt-6 md:mt-10 2xl:mt-20">
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{currentSession.title}</span>
              <span className="font-extrabold text-reghda-blue">عنوان</span>
            </div>
            {currentSession.meet_url && currentSession.meet_url.length > 0 && (
              <div className="w-full flex flex-row items-center justify-center space-x-4">
                <a
                  href={currentSession.meet_url}
                  target="_blank"
                  rel="noreferrer"
                  className="underline">
                  إضغط هنا
                </a>
                <span className="font-extrabold text-reghda-blue">
                  رابط الملتقى
                </span>
              </div>
            )}
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{currentSession.date}</span>
              <span className="font-extrabold text-reghda-blue">التاريخ</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center space-x-2">
              <span className="">دقيقة</span>
              <span>{currentSession.duration}</span>
              <span className="font-extrabold text-reghda-blue">مدة</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{currentSession.time}</span>
              <span className="font-extrabold text-reghda-blue">التوقيت</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center space-x-4">
              <span>{currentSession.status}</span>
              <span className="font-extrabold text-reghda-blue">الحالة</span>
            </div>
            <div className="w-full flex flex-row items-center justify-center">
              <button
                onClick={async () => {
                  setLoading(true);
                  const res = await deleteSessionFromGroupCoaching(
                    currentSession.id
                  );
                  mutate();
                  closing();
                  setLoading(false);
                }}
                className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl outline-none focus:outline-none">
                حذف
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default SessionDetailModal;
