import React from "react";
import DashboardContainer from "./DashboardContainer";
import ContentContainer from "./pages/ContentContainer";
import Sidebar from "./SideBar";
import Overview from "./pages/Overview";
import NormalCourses from "./pages/NormalCourses";
import AddCourse from "./pages/AddCourse";
import CourseSubscribers from "./pages/CourseSubscribers";
import FollowCourses from "./pages/FollowCourses";
import AddCourseFollow from "./pages/AddCourseFollow";
import EditCourseFollow from "./pages/EditCourseFollow";
import EditCourse from "./pages/EditCourse";
import Subscribers from "./pages/Subscribers";
import SubscribersDetail from "./pages/SubscribersDetail";
import Dates from "./pages/Dates";
import MediaCenter from "./pages/MediaCenter";
import MediaCenterType from "./pages/MediaCenterType";
import Settings from "./pages/Settings";
import SubscribersList from "./pages/SubscribersList";
import Sessions from "./pages/Sessions";
import CoursesOrders from "./pages/CoursesOrders";
import CourseOrderDetail from "./pages/CourseOrderDetail";
import GroupCoaching from "./pages/GroupCoaching";
import GroupCoachingDetail from "./pages/GroupCoachingDetail";
import UsersPage from "./pages/UsersPage";
import UserDetails from "./pages/UserDetails";
import GroupCoachingUserDetail from "./pages/GroupCoachingUserDetail";
import Blogs from "./pages/Blogs";
import AddBlog from "./pages/AddBlog";
import EditBlog from "./pages/EditBlog";
import Sayings from "./pages/Sayings";

const Dashboard = ({
  overview,
  normalCourses,
  addCourse,
  editCourse,
  followCourses,
  addCourseFollow,
  editCourseFollow,
  coursesOrders,
  courseOrderDetail,
  subscribers,
  subscribersList,
  subscribersDetail,
  groupCoaching,
  groupCoachingDetail,
  groupCoachingUserDetail,
  dates,
  sessions,
  blogs,
  addBlog,
  editBlog,
  mediaCenter,
  mediaCenterType,
  settings,
  courseSubscribers,
  Users,
  User,
  sayings,
}) => {
  const content = () => {
    switch (true) {
      case overview:
        return <Overview></Overview>;
      case normalCourses:
        return <NormalCourses></NormalCourses>;
      case addCourse:
        return <AddCourse></AddCourse>;
      case editCourse:
        return <EditCourse></EditCourse>;
      case courseSubscribers:
        return <CourseSubscribers></CourseSubscribers>;
      case followCourses:
        return <FollowCourses></FollowCourses>;
      case addCourseFollow:
        return <AddCourseFollow></AddCourseFollow>;
      case editCourseFollow:
        return <EditCourseFollow></EditCourseFollow>;
      case coursesOrders:
        return <CoursesOrders></CoursesOrders>;
      case courseOrderDetail:
        return <CourseOrderDetail></CourseOrderDetail>;
      case subscribers:
        return <Subscribers></Subscribers>;
      case subscribersList:
        return <SubscribersList></SubscribersList>;
      case subscribersDetail:
        return <SubscribersDetail></SubscribersDetail>;
      case dates:
        return <Dates></Dates>;
      case groupCoaching:
        return <GroupCoaching></GroupCoaching>;
      case groupCoachingDetail:
        return <GroupCoachingDetail></GroupCoachingDetail>;
      case groupCoachingUserDetail:
        return <GroupCoachingUserDetail></GroupCoachingUserDetail>;
      case sessions:
        return <Sessions></Sessions>;
      case blogs:
        return <Blogs></Blogs>;
      case addBlog:
        return <AddBlog></AddBlog>;
      case editBlog:
        return <EditBlog></EditBlog>;
      case settings:
        return <Settings></Settings>;
      case mediaCenter:
        return <MediaCenter></MediaCenter>;
      case mediaCenterType:
        return <MediaCenterType></MediaCenterType>;
      case Users:
        return <UsersPage></UsersPage>;
      case User:
        return <UserDetails></UserDetails>;
      case sayings:
        return <Sayings></Sayings>;
      default:
        return <Overview></Overview>;
    }
  };

  return (
    <DashboardContainer>
      <ContentContainer>{content()}</ContentContainer>
      <Sidebar></Sidebar>
    </DashboardContainer>
  );
};

export default Dashboard;
