import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import cn from "classnames";
import AddForm from "./content/normal-courses/AddForm";
import AddContent from "./content/normal-courses/AddContent";
import { useParams, useHistory } from "react-router-dom";

import ChapterSection from "./content/normal-courses/ChapterSection";
import ModuleSection from "./content/normal-courses/ModuleSection";
import Item from "./content/normal-courses/Item";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";

import LoaderModal from "./content/LoaderModal";

import { updateCourse } from "../../../services/course";
import {
  addChapterToServer,
  updateChapter,
  deleteChapterFromServer,
  changeChapterOrder,
} from "../../../services/chapter";

import {
  updateLesson,
  addLessonToServer,
  deleteLessonFromServer,
  changeLessonOrder,
} from "../../../services/lesson";

import { uploadMedia } from "../../../services/media";
import { addItemToServer, deleteItemFromServer } from "../../../services/item";
import { domain } from "./../../../global/domain";

const EditCourse = () => {
  const { type, id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data, mutate, error } = useSWR(
    `https://${domain}/courses/${type}/${id}/details/`,
    fetcher
  );

  const [info, setInfo] = useState(false);
  const infoStyle = cn({ "text-reghda-blue": info });
  const contentStyle = cn({ "text-reghda-blue": !info });

  const [formState, setFormState] = useState({
    img: null,
    courseDescription: data ? data.description : "",
    courseInformation: data ? data.course_information : "",
    courseTitle: data ? data.title : "",
    duration: data ? data.duration : 0,
    price: data ? data.price : "",
    about: data ? data.about_course : "",
    what_you_get: data ? data.what_you_get : "",
    isLocked:
      data && data.isLocked && data.isLocked === true ? "true" : "false",
    showLowIncomePrice:
      data && data.show_low_income_price && data.show_low_income_price === true
        ? "true"
        : "false",
    lowIncomePrice: data ? data.low_income_price : null,
    courseVideo: data ? data.course_video : "",
    is_published: data ? data.is_published : "",
    discounted_price: data ? data.discounted_price : null,
  });

  const [contentState, setContentState] = useState([]);

  useEffect(() => {
    setFormState({
      img: null,
      courseDescription: data ? data.description : "",
      courseInformation: data ? data.course_information : "",
      courseTitle: data ? data.title : "",
      duration: data ? data.duration : 0,
      price: data ? data.price : "",
      about: data ? data.about_course : "",
      what_you_get: data ? data.what_you_get : "",
      isLocked:
        data && data.isLocked && data.isLocked === true ? "true" : "false",
      showLowIncomePrice:
        data &&
        data.show_low_income_price &&
        data.show_low_income_price === true
          ? "true"
          : "false",
      lowIncomePrice: data ? data.low_income_price : null,
      courseVideo: data ? data.course_video : "",
      is_published: data ? data.is_published : "",
      discounted_price: data ? data.discounted_price : null,
    });
    data && setContentState(data.chapters);
  }, [data]);

  // console.log(formState);

  const addChapter = async (title) => {
    setLoading(true);
    const res = await addChapterToServer({
      title: title,
      course: id,
    });

    if (res && res.status === 201) {
      mutate(`https://${domain}/courses/${type}/${id}/details/`);
      data && setContentState(data.chapters);
    } else {
      alert("retry! something wrong");
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">دورات عادية</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">تعديل دورة</h3>
      </div>
      <div className="x-sm:px-3 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end x-sm:space-x-4 md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div className={contentStyle}>محتوى</div>
            <div className={infoStyle}>معلومات عامة</div>
          </div>
          <div>
            {info && (
              <div className="x-sm:p-4 md:p-8 2xl:p-16">
                <AddForm
                  formState={formState}
                  setFormState={setFormState}
                  paid={type === "paid"}></AddForm>
                <div>
                  <button
                    onClick={async (e) => {
                      if (
                        type === "free" &&
                        (formState.courseTitle.length > 0 ||
                          formState.courseDescription.length > 0 ||
                          formState.img)
                      ) {
                        setLoading(true);
                        const formData = new FormData();

                        if (formState.courseTitle) {
                          formData.append(`title`, formState.courseTitle);
                        }
                        formData.append(`is_published`, formState.is_published);

                        formData.append(
                          `description`,
                          formState.courseDescription
                        );

                        if (formState.duration) {
                          formData.append(`duration`, formState.duration);
                        }

                        if (formState.img) {
                          formData.append(`picture`, formState.img);
                        }

                        let res = await updateCourse(type, id, formData);

                        setLoading(false);
                        mutate(`https://${domain}/courses/${type}/${id}`);
                        setInfo(false);
                      }
                      if (type === "paid" && formState.price) {
                        setLoading(true);
                        const formData = new FormData();

                        if (formState.courseTitle) {
                          formData.append(`title`, formState.courseTitle);
                        }

                        formData.append(
                          `description`,
                          formState.courseDescription
                        );
                        formData.append(`is_published`, formState.is_published);
                        if (formState.discounted_price) {
                          formData.append(
                            `discounted_price`,
                            formState.discounted_price
                          );
                        }

                        if (formState.duration) {
                          formData.append(`duration`, formState.duration);
                        }

                        formData.append(`about_course`, formState.about);

                        if (formState.isLocked) {
                          formData.append(`is_locked`, formState.isLocked);
                        }

                        if (formState.price) {
                          formData.append(`price`, formState.price);
                        }

                        if (formState.img) {
                          formData.append(`picture`, formState.img);
                        }

                        if (formState.courseInformation) {
                          formData.append(
                            `course_information`,
                            formState.courseInformation
                          );
                        }
                        if (formState.what_you_get) {
                          formData.append(
                            `what_you_get`,
                            formState.what_you_get
                          );
                        }

                        if (formState.courseVideo) {
                          formData.append(
                            `course_video`,
                            formState.courseVideo
                          );
                        } else {
                          formData.append(`course_video`, "");
                        }

                        if (formState.showLowIncomePrice) {
                          formData.append(
                            `show_low_income_price`,
                            formState.showLowIncomePrice
                          );
                        }
                        if (formState.lowIncomePrice) {
                          formData.append(
                            `low_income_price`,
                            formState.lowIncomePrice
                          );
                        }

                        let res = await updateCourse(type, id, formData);

                        setLoading(false);
                        mutate(`https://${domain}/courses/${type}/${id}`);
                        setInfo(false);
                      }
                    }}
                    className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl">
                    التالي
                  </button>
                </div>
              </div>
            )}
            {!info && (
              <div className="x-sm:p-2 md:p-8 2xl:p-16 space-y-6">
                <AddContent
                  addChapter={addChapter}
                  clearAll={() => setContentState([])}>
                  {/* <ChapterSection number={1}>
                    <LessonSection number={1}>
                      <Item number={1}></Item>
                      <Item number={2}></Item>
                    </LessonSection>
                  </ChapterSection> */}
                  {contentState &&
                    contentState.map((chapter, i1) => {
                      return (
                        <ChapterSection
                          key={i1}
                          title={chapter.title}
                          addModule={async (title) => {
                            setLoading(true);
                            const res = await addLessonToServer({
                              chapter: chapter.id,
                              title,
                            });
                            if (res && res.status === 201) {
                              mutate(
                                `https://${domain}/courses/${type}/${id}/details/`
                              );
                              data && setContentState(data.chapters);
                            } else {
                              alert("retry! something wrong");
                            }
                            setLoading(false);
                          }}
                          updateChapter={async (newTitle) => {
                            setLoading(true);
                            const res = await updateChapter(chapter.id, {
                              title: newTitle,
                            });
                            if (res && res.status === 200) {
                              mutate(
                                `https://${domain}/courses/${type}/${id}/details/`
                              );
                              data && setContentState(data.chapters);
                            } else {
                              alert("retry! something wrong");
                            }
                            setLoading(false);
                          }}
                          deleteChapter={async () => {
                            setLoading(true);
                            const res = await deleteChapterFromServer(
                              chapter.id
                            );
                            if (res && res.status === 204) {
                              mutate(
                                `https://${domain}/courses/${type}/${id}/details/`
                              );
                              data && setContentState(data.chapters);
                            } else {
                              alert("retry! something wrong");
                            }
                            setLoading(false);
                          }}
                          up={async () => {
                            setLoading(true);
                            const res = await changeChapterOrder({
                              action: "up",
                              chapter: chapter.id,
                            });
                            if (res && res.status === 200) {
                              mutate(
                                `https://${domain}/courses/${type}/${id}/details/`
                              );
                              data && setContentState(data.chapters);
                            } else {
                              alert("retry! something wrong");
                            }
                            setLoading(false);
                          }}
                          down={async () => {
                            setLoading(true);
                            const res = await changeChapterOrder({
                              action: "down",
                              chapter: chapter.id,
                            });
                            if (res && res.status === 200) {
                              mutate(
                                `https://${domain}/courses/${type}/${id}/details/`
                              );
                              data && setContentState(data.chapters);
                            } else {
                              alert("retry! something wrong");
                            }
                            setLoading(false);
                          }}>
                          {chapter.modules.map((module, i2) => {
                            return (
                              <ModuleSection
                                addItem={async (data) => {
                                  setLoading(true);
                                  if (data.from === "media") {
                                    let formData = new FormData();

                                    formData.append(`module`, module.id);
                                    formData.append(
                                      `title`,
                                      data.file[
                                        `uploaded_${data.type}`
                                      ].replace(
                                        `http://${domain}/media/${data.type}s/`,
                                        ""
                                      )
                                    );
                                    formData.append(
                                      `url`,
                                      data.file[`uploaded_${data.type}`]
                                    );

                                    let res = await addItemToServer(
                                      data.type,
                                      formData
                                    );

                                    console.log("🌹", res);

                                    if (res && res.status === 201) {
                                      mutate(
                                        `https://${domain}/courses/${type}/${id}/details/`
                                      );
                                      data && setContentState(data.chapters);
                                    } else {
                                      alert(
                                        "something wrong from the server, retry!"
                                      );
                                      console.log("❌❗", res);
                                    }
                                  }

                                  // add the new item to it
                                  if (data.from === "youtube") {
                                    let formData = new FormData();

                                    formData.append(`module`, module.id);
                                    formData.append(`title`, "Youtube Link");
                                    formData.append(`url`, data.url);

                                    let res = await addItemToServer(
                                      data.type,
                                      formData
                                    );

                                    console.log("🌹", res);

                                    if (res && res.status === 201) {
                                      mutate(
                                        `https://${domain}/courses/${type}/${id}/details/`
                                      );
                                      data && setContentState(data.chapters);
                                    } else {
                                      alert(
                                        "something wrong from the server, retry!"
                                      );
                                      console.log("❌❗", res);
                                    }
                                  }

                                  if (data.from === "desktop") {
                                    const formData = new FormData();
                                    let res;

                                    if (data.type === "videos") {
                                      let formData0 = new FormData();
                                      formData0.append(
                                        `uploaded_${data.type.replace(
                                          "s",
                                          ""
                                        )}`,
                                        data.file
                                      );
                                      let res0 = await uploadMedia(
                                        formData0,
                                        data.type
                                      );

                                      formData.append(`module`, module.id);
                                      formData.append(`title`, data.file.name);
                                      formData.append(
                                        `url`,
                                        res0.data.uploaded_video
                                      );

                                      res = await addItemToServer(
                                        data.type,
                                        formData
                                      );
                                    } else {
                                      formData.append(`url`, "");
                                      formData.append(`module`, module.id);
                                      formData.append(`title`, data.file.name);
                                      formData.append(
                                        `${data.type.replace("s", "")}_content`,
                                        data.file
                                      );

                                      res = await addItemToServer(
                                        data.type,
                                        formData
                                      );
                                    }

                                    if (res && res.status === 201) {
                                      mutate(
                                        `https://${domain}/courses/${type}/${id}/details/`
                                      );
                                      data && setContentState(data.chapters);
                                    } else {
                                      alert(
                                        "something wrong from the server, retry!"
                                      );
                                      console.log("❌❗", res);
                                    }
                                  }
                                  setLoading(false);
                                }}
                                updateLesson={async (newTitle) => {
                                  setLoading(true);
                                  const res = await updateLesson(module.id, {
                                    title: newTitle,
                                  });
                                  if (res && res.status === 200) {
                                    mutate(
                                      `https://${domain}/courses/${type}/${id}/details/`
                                    );
                                    data && setContentState(data.chapters);
                                  } else {
                                    alert("retry! something wrong");
                                  }
                                  setLoading(false);
                                }}
                                deleteLesson={async () => {
                                  setLoading(true);
                                  const res = await deleteLessonFromServer(
                                    module.id
                                  );
                                  if (res && res.status === 204) {
                                    mutate(
                                      `https://${domain}/courses/${type}/${id}/details/`
                                    );
                                    data && setContentState(data.chapters);
                                  } else {
                                    alert("retry! something wrong");
                                  }
                                  setLoading(false);
                                }}
                                up={async () => {
                                  setLoading(true);
                                  const res = await changeLessonOrder({
                                    action: "up",
                                    module: module.id,
                                  });
                                  console.log(res, "🎈");
                                  if (res && res.status === 200) {
                                    mutate(
                                      `https://${domain}/courses/${type}/${id}/details/`
                                    );
                                    data && setContentState(data.chapters);
                                  } else {
                                    alert("retry! something wrong");
                                  }
                                  setLoading(false);
                                }}
                                down={async () => {
                                  setLoading(true);
                                  const res = await changeLessonOrder({
                                    action: "down",
                                    module: module.id,
                                  });
                                  console.log(res, "🎈");
                                  if (res && res.status === 200) {
                                    mutate(
                                      `https://${domain}/courses/${type}/${id}/details/`
                                    );
                                    data && setContentState(data.chapters);
                                  } else {
                                    alert("retry! something wrong");
                                  }
                                  setLoading(false);
                                }}
                                key={i2}
                                order={module.order}
                                title={module.title}>
                                {module["videos"].map((item, i3) => {
                                  return (
                                    <Item
                                      deleteItem={async (type) => {
                                        setLoading(true);
                                        const res = await deleteItemFromServer(
                                          type,
                                          item.id
                                        );

                                        if (res && res.status === 204) {
                                          let newState = contentState.map(
                                            (e) => {
                                              if (e.id === chapter.id) {
                                                chapter.modules.map((e2) => {
                                                  if (e2.id === module.id) {
                                                    e2.videos =
                                                      e2.videos.filter(
                                                        (e2) =>
                                                          e2.id !== item.id
                                                      );
                                                    return e2;
                                                  }
                                                  return e2;
                                                });
                                              }
                                              return e;
                                            }
                                          );
                                          setContentState(newState);
                                          // back
                                          mutate();
                                          data &&
                                            setContentState(data.chapters);
                                        } else {
                                          alert("something wrong! retry");
                                        }
                                        setLoading(false);
                                      }}
                                      key={i3}
                                      number={item.number}
                                      title={item.title}></Item>
                                  );
                                })}
                                {module["audios"].map((item, i3) => {
                                  return (
                                    <Item
                                      deleteItem={async (type) => {
                                        setLoading(true);
                                        const res = await deleteItemFromServer(
                                          type ? type : "audios",
                                          item.id
                                        );

                                        if (res && res.status === 204) {
                                          let newState = contentState.map(
                                            (e) => {
                                              if (e.id === chapter.id) {
                                                chapter.modules.map((e2) => {
                                                  if (e2.id === module.id) {
                                                    e2.audios =
                                                      e2.audios.filter(
                                                        (e2) =>
                                                          e2.id !== item.id
                                                      );
                                                    return e2;
                                                  }
                                                  return e2;
                                                });
                                              }
                                              return e;
                                            }
                                          );
                                          setContentState(newState);
                                          // back
                                          mutate();
                                          data &&
                                            setContentState(data.chapters);
                                        } else {
                                          alert("something wrong! retry");
                                        }
                                        setLoading(false);
                                      }}
                                      key={i3}
                                      number={item.number}
                                      title={item.title}></Item>
                                  );
                                })}
                                {module["images"].map((item, i3) => {
                                  return (
                                    <Item
                                      deleteItem={async (type) => {
                                        setLoading(true);
                                        const res = await deleteItemFromServer(
                                          type,
                                          item.id
                                        );

                                        if (res && res.status === 204) {
                                          let newState = contentState.map(
                                            (e) => {
                                              if (e.id === chapter.id) {
                                                chapter.modules.map((e2) => {
                                                  if (e2.id === module.id) {
                                                    e2.images =
                                                      e2.images.filter(
                                                        (e2) =>
                                                          e2.id !== item.id
                                                      );
                                                    return e2;
                                                  }
                                                  return e2;
                                                });
                                              }
                                              return e;
                                            }
                                          );
                                          setContentState(newState);
                                          // back
                                          mutate();
                                          data &&
                                            setContentState(data.chapters);
                                        } else {
                                          alert("something wrong! retry");
                                        }
                                        setLoading(false);
                                      }}
                                      key={i3}
                                      number={item.number}
                                      title={item.title}></Item>
                                  );
                                })}
                                {module["files"].map((item, i3) => {
                                  return (
                                    <Item
                                      deleteItem={async (type) => {
                                        setLoading(true);
                                        const res = await deleteItemFromServer(
                                          type,
                                          item.id
                                        );

                                        if (res && res.status === 204) {
                                          let newState = contentState.map(
                                            (e) => {
                                              if (e.id === chapter.id) {
                                                chapter.modules.map((e2) => {
                                                  if (e2.id === module.id) {
                                                    e2.files = e2.files.filter(
                                                      (e2) => e2.id !== item.id
                                                    );
                                                    return e2;
                                                  }
                                                  return e2;
                                                });
                                              }
                                              return e;
                                            }
                                          );
                                          setContentState(newState);
                                          // back
                                          mutate();
                                          data &&
                                            setContentState(data.chapters);
                                        } else {
                                          alert("something wrong! retry");
                                        }
                                        setLoading(false);
                                      }}
                                      key={i3}
                                      number={item.number}
                                      title={item.title}></Item>
                                  );
                                })}
                              </ModuleSection>
                            );
                          })}
                        </ChapterSection>
                      );
                    })}
                </AddContent>
                <div>
                  <Link
                    to="/admin/normal-courses"
                    className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl">
                    حفظ
                  </Link>
                  <button
                    onClick={() => {
                      setLoading(true);
                      mutate(
                        `https://${domain}/courses/${type}/${id}/details/`
                      );
                      setLoading(false);
                      if (type === "free") {
                        setFormState({
                          img: null,
                          courseDescription: data ? data.description : "",
                          courseInformation: data
                            ? data.course_information
                            : "",
                          courseTitle: data ? data.title : "",
                          duration: data ? data.duration : 0,
                          price: data ? data.price : "",
                          about: data ? data.about_course : "",
                          what_you_get: data ? data.what_you_get : "",
                          isLocked:
                            data && data.isLocked && data.isLocked === true
                              ? "true"
                              : "false",
                          showLowIncomePrice:
                            data &&
                            data.show_low_income_price &&
                            data.show_low_income_price === true
                              ? "true"
                              : "false",
                          lowIncomePrice: data ? data.low_income_price : null,
                          courseVideo: data ? data.course_video : "",
                          is_published: data ? data.is_published : "",
                          discounted_price: data ? data.discounted_price : null,
                        });
                      }
                      if (type === "paid") {
                        setFormState({
                          img: null,
                          courseDescription: data ? data.description : "",
                          courseInformation: data
                            ? data.course_information
                            : "",
                          courseTitle: data ? data.title : "",
                          duration: data ? data.duration : 0,
                          price: data ? data.price : "",
                          about: data ? data.about_course : "",
                          what_you_get: data ? data.what_you_get : "",
                          isLocked:
                            data && data.isLocked && data.isLocked === true
                              ? "true"
                              : "false",
                          showLowIncomePrice:
                            data &&
                            data.show_low_income_price &&
                            data.show_low_income_price === true
                              ? "true"
                              : "false",
                          lowIncomePrice: data ? data.low_income_price : null,
                          courseVideo: data ? data.course_video : "",
                          is_published: data ? data.is_published : "",
                          discounted_price: data ? data.discounted_price : null,
                        });
                      }
                      setInfo(true);
                    }}
                    className="bg-reghda-blue float-right text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl">
                    السابق
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default EditCourse;
