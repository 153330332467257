import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";
import { domain } from "../../../global/domain";

const CourseOrderDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/orders/my-orders/${id}/`,
    fetcher
  );

  if (!data) {
    return <LoaderModal modalIsOpen={true}></LoaderModal>;
  }

  return (
    <div className="min-h-screen h-full flex flex-col space-y-10 overflow-y-auto x-sm:px-4 md:px-0 pb-8">
      <div className="px-8 pt-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          تفاصيل الطلب
        </h3>
      </div>
      <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-6 2xl:p-10">
          <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-4 2xl:pb-8">
            <div className="flex flex-row space-x-1 items-center self-end font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <h3>المشتريات</h3>
            </div>
          </div>
          <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12">
            <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
              <i className="w-1/3">السعر</i>
              <i className="w-1/3">المنتج</i>
              <i className="w-1/3">صنف</i>
            </div>
            {data &&
              data.items.map((item) => (
                <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                  <i className="w-1/3 flex flex-row items-center justify-center space-x-2">
                    <span>دولار</span>
                    <span>{item.price}</span>
                  </i>
                  <i className="w-1/3">{item.title}</i>
                  <i className="w-1/3">{item.type}</i>
                </div>
              ))}
            <div className="flex flex-row space-x-4 justify-between font-extrabold text-reghda-blue x-sm:text-base md:text-lg 2xl:text-5xl">
              <i></i>
              <i>المجموع: {data.total_cost} دولار</i>
              <i></i>
            </div>
          </div>
        </div>
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
          <div className="flex flex-col items-center justify-around space-y-4 x-sm:pb-2 md:pb-4 2xl:pb-8">
            <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>معلومات المشتري</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3> {data.user.given_name} </h3>
              <h3 className="text-reghda-blue">:الاسم</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3> {data.user.family_name} </h3>
              <h3 className="text-reghda-blue">:اللقب</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.user.email}</h3>
              <h3 className="text-reghda-blue">:البريد الالكتروني</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.payment_method}</h3>
              <h3 className="text-reghda-blue">:طريقة الدفع</h3>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CourseOrderDetail;
