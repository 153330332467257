import React from "react";
import { IconContext } from "react-icons";
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { iconsColor } from "./../../../../../global/iconsColor";

const FourthCardsSection = () => {
  return (
    <div className="x-sm:w-full x-sm:pb-4 md:w-11/12 flex x-sm:flex-col x-sm:space-y-4 md:flex-row md:space-y-0 md:space-x-8 2xl:space-x-16">
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <AiFillYoutube></AiFillYoutube>
        </IconContext.Provider>
        <div className="text-right">
          <p>1520</p>
          <p>مشترك</p>
        </div>
      </div>
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <AiFillInstagram></AiFillInstagram>
        </IconContext.Provider>
        <div className="text-right">
          <p>2500</p>
          <p>متابع</p>
        </div>
      </div>
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-2xl 2xl:text-4xl md:p-4 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "4em", style: { color: iconsColor } }}
        >
          <FaTelegram></FaTelegram>
        </IconContext.Provider>
        <div className="text-right">
          <p>500</p>
          <p>مشترك</p>
        </div>
      </div>
    </div>
  );
};

export default FourthCardsSection;
