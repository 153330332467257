import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import cn from "classnames";

import { editSaying } from "../../../../../services/sayings";
import { mutate } from "swr";
import { domain } from "../../../../../global/domain";
import ModalDelete from "./ModalDelete";

const Say = ({ deleteSay, say, formState, setFormState, setLoading }) => {
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4  ",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "bg-reghda-black  rounded-lg p-2 absolute hidden top-8 absolute",
    {
      dropdownContent: menuOpen,
    }
  );

  return (
    <>
      <div
        key={say.id}
        className="flex x-sm:flex-col-reverse md:flex-row items-center justify-between border-t-2 border-gray-100 md:pt-4 mt-8">
        {!edit && (
          <div className="w-9/12 text-right leading-loose">{say.body}</div>
        )}

        {edit && (
          <button
            onClick={async () => {
              if (formState.author.length < 1 || formState.body.length < 1) {
                return;
              }
              setLoading(true);
              let res = await editSaying(say.id, {
                author: formState.author,
                body: formState.body,
              });
              mutate(`https://${domain}/about/sayings/`);
              setFormState({
                author: "",
                body: "",
              });
              setEdit(false);
              setMenuOpen(false);
              setLoading(false);
            }}
            className="bg-reghda-blue p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
            حفظ
          </button>
        )}

        {edit && (
          <div className="w-6/12">
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    body: event.target.value,
                  })
                }
                defaultValue={formState.body}
                placeholder="مقولة"
                rows="1"
                type="text"
                className="w-2/3 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
          </div>
        )}

        {!edit && (
          <div className="w-2/12 text-center leading-loose font-extrabold text-reghda-blue">
            {say.author}
          </div>
        )}

        {edit && (
          <div className="w-5/12">
            <fieldset className="w-full flex flex-row items-center justify-center">
              <textarea
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    author: event.target.value,
                  })
                }
                defaultValue={formState.author}
                rows="1"
                placeholder="قائل"
                type="text"
                className="w-2/3 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </fieldset>
          </div>
        )}

        <div className="w-1/12 flex x-sm:flex-col-reverse x-sm:space-y-2 md:flex-row items-center pt-2 md:pt-0">
          <div className={`${menuStyle} absolute md:pr-2 md:pl-4`}>
            <div onClick={() => setMenuOpen(!menuOpen)} className="py-2">
              <IconContext.Provider
                value={{ size: "1.5em", style: { color: "#5b5b5b" } }}>
                <BsThreeDots></BsThreeDots>
              </IconContext.Provider>
            </div>
            <div className={menuItemsStyle}>
              <div
                onClick={() => {
                  setEdit(!edit);
                  setFormState({
                    author: say.author,
                    body: say.body,
                  });
                }}
                className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer">
                <span className="text-sm">تعديل</span>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#fff" } }}>
                  <AiTwotoneEdit></AiTwotoneEdit>
                </IconContext.Provider>
              </div>
              <div
                onClick={() => setOpenModal(true)}
                className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer">
                <span className="text-sm">حذف</span>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#fff" } }}>
                  <AiFillDelete></AiFillDelete>
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDelete
        open={openModal}
        handleClose={() => setOpenModal(false)}
        deleteSay={deleteSay}
      />
    </>
  );
};

export default Say;
