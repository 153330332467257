import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { RiFileAddLine } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";

import PreviewFile from "./content/media-center/PreviewFile";
import PreviewAudio from "./content/media-center/PreviewAudio";
import PreviewVideo from "./content/media-center/PreviewVideo";
import PreviewImage from "./content/media-center/PreviewImage";
import LoaderModal from "./content/LoaderModal";
import ProgressModal from "./content/ProgressModal";

import useSWR, { mutate } from "swr";
import { uploadMedia, deleteMedia } from "../../../services/media";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import { iconsColor } from "./../../../global/iconsColor";

const MediaCenterType = () => {
  const { type } = useParams();
  const history = useHistory();

  const { data, error } = useSWR(
    `https://${domain}/media-library/${type}/`,
    fetcher
  );

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);

  const [loading, setLoading] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);

  const whichType = () => {
    switch (type) {
      case "files":
        return ".doc,.docx,.pdf";
      case "images":
        return "image/*";
      case "audios":
        return "audio/*";
      case "videos":
        return "video/*";
      default:
        return ".doc,.docx,.pdf";
    }
  };

  const deleteOne = async (type, id) => {
    setLoading(true);
    let res = await deleteMedia(type, id);
    setLoading(false);
    mutate(`https://${domain}/media-library/${type}/`);
  };

  const whichPreview = (file, i) => {
    switch (type) {
      case "files":
        return (
          <PreviewFile
            deleteOne={() => deleteOne(type, file.id)}
            key={i}
            file={file}></PreviewFile>
        );
      case "images":
        return (
          <PreviewImage
            deleteOne={() => deleteOne(type, file.id)}
            key={i}
            file={file}></PreviewImage>
        );
      case "audios":
        return (
          <PreviewAudio
            deleteOne={() => deleteOne(type, file.id)}
            key={i}
            file={file}></PreviewAudio>
        );
      case "videos":
        return (
          <PreviewVideo
            deleteOne={() => deleteOne(type, file.id)}
            key={i}
            file={file}></PreviewVideo>
        );
      default:
        return (
          <PreviewFile
            deleteOne={() => deleteOne(type, file.id)}
            key={i}
            file={file}
            name={file.name}></PreviewFile>
        );
    }
  };

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8">
        <h3 className="text-right md:text-2xl 2xl:text-6xl font-extrabold">
          مكتبة الوسائط
        </h3>
      </div>
      <div className="x-sm:px-3 md:px-6 2xl:p-12 mb-16">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-row x-sm:p-3 md:p-6 2xl:p-12 x-sm:text-xxs md:text-sm 2xl:text-4xl">
          <div className="grid x-sm:grid-cols-3 sm:grid-cols-6 md:grid-cols-6 2xl:grid-cols-6 gap-8 justify-around">
            <div
              onClick={() => {
                history.goBack();
              }}
              class="flex flex-col items-center space-y-2 cursor-pointer">
              <IconContext.Provider
                value={{ size: "8em", style: { color: iconsColor } }}>
                <BiArrowBack></BiArrowBack>
              </IconContext.Provider>
              <p className="text-center break-all">عودة</p>
            </div>
            {data &&
              data.length > 0 &&
              data.map((file, i) => {
                return whichPreview(file, i);
              })}
            <div className="text-gray-300 cursor-pointer">
              <input
                onChange={async (event) => {
                  if (event.target.files[0]) {
                    setProgressOpen(true);
                    setFile(event.target.files[0]);

                    const formData = new FormData();
                    formData.append(
                      `uploaded_${type.replace("s", "")}`,
                      event.target.files[0]
                    );

                    let config = {
                      onUploadProgress: function (progressEvent) {
                        let progress = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(progress);
                      },
                    };

                    let res = await uploadMedia(formData, type, config);
                    setProgressOpen(false);

                    // console.log("🌹🌹🌹", res);
                    mutate(`https://${domain}/media-library/${type}/`);
                  }
                }}
                id="upload-something"
                type="file"
                accept={whichType()}
                style={{ display: "none" }}
              />
              <div
                className="flex flex-col items-center"
                onClick={() => {
                  document.querySelector("#upload-something").click();
                }}>
                <IconContext.Provider
                  value={{ size: "8em", style: { color: "#e6e6e6" } }}>
                  <RiFileAddLine></RiFileAddLine>
                </IconContext.Provider>
                <p>إضافة</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProgressModal
        progress={progress}
        modalIsOpen={progressOpen}></ProgressModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default MediaCenterType;
