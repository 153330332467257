import React, { useState } from "react";
import Modal from "react-modal";
import LoaderModal from "../LoaderModal";
import Schedule from "./Schedule";

const CalendarModal = ({ isOpen, closing, mutate, users, currentChapter }) => {
  const [loading, setLoading] = useState(false);
  console.log(users, "👀");

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "90%",
          // height: "80%",
        },
      }}
    >
      <div class="flex flex-row justify-start pb-3">
        <div
          onClick={closing}
          class="self-start cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col space-y-3 items-center">
        <h3 className="text-reghda-blue x-sm:text-xs md:text-3xl 2xl:text-5xl font-semibold">
          جدول المواعيد
        </h3>
        <Schedule
          currentChapter={currentChapter}
          users={users}
          closing={closing}
          mutate={mutate}
        ></Schedule>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default CalendarModal;
