import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import UsersModal from "./content/group-coaching/UsersModal";
import SessionDetailModal from "./content/group-coaching/SessionDetailModal";
import CalendarModal from "./content/group-coaching/CalendarModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";

const GroupCoachingDetail = () => {
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);

  const [sessionDetailModal, setSessionDetailModal] = useState(false);
  const [usersModal, setUsersModal] = useState(false);
  const [calendarModal, setCalendarModal] = useState(false);

  const [currentUsersList, setCurrentUsersList] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);

  const { data, error } = useSWR(
    `https://${domain}/subscriptions/progress/group/${courseId}`,
    fetcher
  );

  const sessions = useSWR(
    `https://${domain}/appointments/table/group/${courseId}`,
    fetcher
  );

  const getWaitingUsersForChapter = (currentChapterId) => {
    let users = data.filter((chapter) => chapter.id === currentChapterId)[0]
      .users;

    return users.filter(
      (user) => user.status === "في الإنتظار" || user.status === "مكتمل"
    );
  };

  const DaysArabic = [
    "الأحد",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  return (
    <div className="min-h-screen h-full flex flex-col space-y-10 overflow-y-auto x-sm:px-4 md:px-0 pb-8">
      <div className="px-8 pt-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          تفاصيل الدورة الجماعية
        </h3>
      </div>
      <div className="px-8 x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-6 2xl:p-10">
          <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-8 2xl:pb-12">
            <div className="flex flex-row space-x-1 items-center self-end font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <h3>تقدم المشتركين</h3>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-14">
            {data &&
              data.map((chapter, i) => {
                return (
                  <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12">
                    <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
                      <i className="w-full"> {chapter.title} </i>
                    </div>
                    <div
                      onClick={() => {
                        setCurrentUsersList(chapter.users);
                        setUsersModal(true);
                      }}
                      className="flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                      {chapter.users.map((user, i) => {
                        if (i < 4) {
                          return (
                            <img
                              className="rounded-full md:w-14 md:h-14 2xl:w-28 2xl:h-28 picfit cursor-pointer"
                              alt="logo"
                              src={user.image}
                            />
                          );
                        }
                        return null;
                      })}
                      {chapter.users.length > 4 && (
                        <div className="cursor-pointer border-2 border-gray-100 rounded-full md:w-14 md:h-14 2xl:w-28 2xl:h-28 text-gray-300 flex flex-col items-center justify-center">
                          +{chapter.users.length - 4}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="x-sm:w-full md:w-1/6"></div>
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
          <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-8 2xl:pb-12">
            <div className="flex flex-row space-x-1 items-center self-end font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <h3>الجلسات</h3>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-8">
            {sessions.data &&
              sessions.data.map((chapter) => {
                return (
                  <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12">
                    <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
                      <i className="w-full"> {chapter.title} </i>
                    </div>
                    {chapter.session ? (
                      <div
                        onClick={() => {
                          setCurrentSession(chapter.session);
                          setSessionDetailModal(true);
                        }}
                        className="cursor-pointer flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                        <div className="flex flex-row justify-around space-x-4 border-b-2 border-gray-300 x-sm:pb-2 md:pb-4 2xl:pb-8 x-sm:pb-2 md:mb-4 2xl:mb-8">
                          <div className="flex flex-col bg-reghda-black text-reghda-blue rounded-lg x-sm:p-1 md:p-2 2xl:p-6 x-sm:text-sm md:text-lg 2xl:text-xl">
                            <p>
                              {Number(chapter.session.time.slice(0, 2)) < 12 ? (
                                <span>{chapter.session.time.slice(0, 5)}</span>
                              ) : (
                                <span>
                                  0
                                  {Number(chapter.session.time.slice(0, 2)) %
                                    12}
                                  :{chapter.session.time.slice(3, 5)}
                                </span>
                              )}
                            </p>
                            <p className="text-center">
                              {Number(chapter.session.time.slice(0, 2)) < 12
                                ? "AM"
                                : "PM"}
                            </p>
                          </div>
                          <div className="text-right flex flex-col x-sm:text-sm md:text-lg 2xl:text-3xl 2xl:space-y-4 font-bold">
                            <p>
                              {
                                DaysArabic[
                                  new Date(chapter.session.date).getDay()
                                ]
                              }
                            </p>
                            <p className="text-gray-300">
                              {chapter.session.date.split("-")[2]}/
                              {chapter.session.date.split("-")[1]}/
                              {chapter.session.date.split("-")[0]}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                        <p
                          onClick={() => {
                            setCurrentChapter(chapter.id);
                            setCalendarModal(true);
                          }}
                          className="text-reghda-black underline cursor-pointer">
                          برمج جلسة لهذا المستوى
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      {data && (
        <UsersModal
          courseId={courseId}
          currentUsersList={currentUsersList}
          isOpen={usersModal}
          closing={() => setUsersModal(false)}></UsersModal>
      )}
      {data && currentSession && (
        <SessionDetailModal
          currentSession={currentSession}
          isOpen={sessionDetailModal}
          closing={() => setSessionDetailModal(false)}
          mutate={() =>
            mutate(`https://${domain}/appointments/table/group/${courseId}`)
          }></SessionDetailModal>
      )}
      {data && currentChapter && (
        <CalendarModal
          currentChapter={currentChapter}
          users={getWaitingUsersForChapter(currentChapter)}
          isOpen={calendarModal}
          closing={() => setCalendarModal(false)}
          mutate={() =>
            mutate(`https://${domain}/appointments/table/group/${courseId}`)
          }></CalendarModal>
      )}
    </div>
  );
};

export default GroupCoachingDetail;
