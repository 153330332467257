import React from "react";

const DateRow = ({ date }) => {
  const DaysArabic = [
    "الأحد",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  return (
    <div className="flex flex-row justify-around space-x-4 border-b-2 border-gray-300 x-sm:pb-2 md:pb-4 2xl:pb-8 x-sm:pb-2 md:mb-4 2xl:mb-8 md:mt-4 2xl:mt-8">
      <div className="flex flex-col bg-reghda-black text-reghda-blue rounded-lg x-sm:p-1 md:p-2 2xl:p-6 x-sm:text-sm md:text-lg 2xl:text-xl">
        <p>
          {Number(date.time.slice(0, 2)) < 12 ? (
            <span>{date.time.slice(0, 5)}</span>
          ) : (
            <span>
              0{Number(date.time.slice(0, 2)) % 12}:{date.time.slice(3, 5)}
            </span>
          )}
        </p>
        <p className="text-center">
          {Number(date.time.slice(0, 2)) < 12 ? "AM" : "PM"}
        </p>
      </div>
      <div className="text-right flex flex-col x-sm:text-sm md:text-lg 2xl:text-3xl 2xl:space-y-4 font-bold">
        <p>{date.type.title}</p>
        <p>{DaysArabic[new Date(date.date).getDay()]}</p>
        <p className="text-gray-300">
          {date.date.split("-")[2]}/{date.date.split("-")[1]}/
          {date.date.split("-")[0]}
        </p>
      </div>
    </div>
  );
};

export default DateRow;
