import React, { useState } from "react";
import Modal from "react-modal";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "../../../../../global/domain";
import { IconContext } from "react-icons";
import LoaderModal from "../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useHistory } from "react-router-dom";

const UsersModal = ({ isOpen, closing, currentUsersList, courseId }) => {
  const screenWidth = useWindowWidth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}
    >
      <div class="flex flex-row justify-start pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>

      <>
        <h1 className="x-sm:text-xs md:text-base 2xl:text-xl text-reghda-blue text-center">
          قائمة المشتركين في هذا المستوى
        </h1>
        <div className="h-72 2xl:h-80 md:mt-8 2xl:mt-12">
          <div className="flex flex-col overflow-y-auto items-center justify-center x-sm:space-y-3 md:space-y-6 2xl:space-y-12 x-sm:text-xs md:text-base 2xl:text-xl">
            {currentUsersList.map((user) => (
              <div
                onClick={() => {
                  history.push(`/admin/group-courses/${courseId}/${user.id}`);
                  // setCurrentUser(user.id);
                }}
                className="cursor-pointer flex flex-row items-center justify-around x-sm:w-full text-reghda-black text-center"
              >
                {user.status === "في الإنتظار" && (
                  <div className="w-1/3 text-yellow-300">{user.status}</div>
                )}

                {user.status === "مكتمل" && (
                  <div className="w-1/3 text-green-300">{user.status}</div>
                )}

                {user.status !== "مكتمل" && user.status !== "في الإنتظار" && (
                  <div className="w-1/3">{user.status}</div>
                )}

                <div className="w-2/3 ">{`${user.given_name} ${user.family_name}`}</div>
                <div className="w-1/3 flex flex-row items-center justify-center">
                  <img
                    className="rounded-full md:w-14 md:h-14 2xl:w-28 2xl:h-28 picfit"
                    alt="logo"
                    src={user.image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </>

      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default UsersModal;
