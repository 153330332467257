import React from "react";
import { IconContext } from "react-icons";
import { FaRegUserCircle } from "react-icons/fa";
import { BsCalendar } from "react-icons/bs";
import DateRow from "./DateRow";
import { Link } from "react-router-dom";
import useSwr from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";

const ThirdDetailSection = () => {
  const { data, error } = useSwr(
    `https://${domain}/appointments/coming-sessions`,
    fetcher
  );

  const subs = useSwr(`https://${domain}/subscriptions/latest`, fetcher);

  return (
    <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
      <div className="x-sm:w-full md:w-2/3 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
        <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-4 2xl:pb-8">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <h3>المشتركين الجدد</h3>
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#5b5b5b" } }}>
              <FaRegUserCircle></FaRegUserCircle>
            </IconContext.Provider>
          </div>
        </div>
        <div className="flex flex-col md:space-y-8 2xl:space-y-12">
          <div className="flex flex-row items-center justify-center text-center text-lg font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
            <i className="w-1/3">دورة</i>
            <i className="w-1/3">تاريخ الأنضمام</i>
            <i className="w-1/3">الأسم</i>
          </div>
          {subs.data &&
            subs.data.map((sub) => {
              return (
                <div className="flex flex-row items-center justify-center text-center text-lg font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                  <i className="w-1/3">{sub.course.title}</i>
                  <i className="w-1/3"> {sub.debut_date}</i>
                  <i className="w-1/3">{`${sub.user.family_name} ${sub.user.given_name}`}</i>
                </div>
              );
            })}
        </div>
      </div>
      <div className="x-sm:w-full md:w-1/3 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
        <div className="flex flex-col x-sm:pb-2 md:pb-4 2xl:pb-8">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <h3>الجلسات القادمة</h3>
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#5b5b5b" } }}>
              <BsCalendar></BsCalendar>
            </IconContext.Provider>
          </div>
          {data && (
            <div className="flex flex-col">
              {data.map((date) => (
                <DateRow key={date.id} date={date} />
              ))}
            </div>
          )}
          <div className="text-center mt-8">
            <Link
              to="/admin/dates"
              className="bg-reghda-black text-white x-sm:p-1 x-sm:px-4 md:p-2 md:px-8 rounded-xl x-sm:text-xs md:text-base 2xl:text-4xl">
              تصفح جميع المواعيد
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdDetailSection;
