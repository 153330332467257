import React, { useState, useEffect } from "react";
import cn from "classnames";
import SessionModal from "./SessionModal";

const TodaySessions = ({ data }) => {
  const [sessionModel, setSessionModel] = useState(false);
  const [clickedSession, setClickedSession] = useState(null);

  const allTimes = [
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
  ];

  console.log(data, "🎉");

  return (
    <div className="flex flex-col rounded-xl">
      {allTimes.map((e, i) => {
        let btr = false;
        let bbr = false;
        if (e === "08:00") {
          btr = true;
        }
        if (e === "21:00") {
          bbr = true;
        }
        let style1 = cn(
          "w-1/4 bg-reghda-blue text-white md:text-lg 2xl:text-5xl text-center p-4",
          {
            "rounded-tr-xl": btr,
            "rounded-br-xl": bbr,
          }
        );

        return (
          <div key={i} className="flex flex-row">
            {data &&
              data.length > 0 &&
              data.map((session) => {
                return session.disabled_times.map((time, i) => {
                  if (e === time) {
                    return (
                      <div
                        onClick={() => {
                          setClickedSession(session);
                          setSessionModel(true);
                        }}
                        className="border-0 cursor-pointer w-3/4 bg-reghda-black text-white md:text-lg 2xl:text-4xl text-center p-4 session flex flex-row items-center justify-around"
                      >
                        {i ===
                          Math.floor(session.disabled_times.length / 2) - 1 && (
                          <p>{session.title}</p>
                        )}
                        {i ===
                          Math.floor(session.disabled_times.length / 2) && (
                          <p>
                            {`${session.user.given_name} ${session.user.family_name}  مع`}
                          </p>
                        )}
                        {session.disabled_times.length === 1 && (
                          <p>{session.title}</p>
                        )}
                      </div>
                    );
                  } else {
                    return <div className="flex-auto"></div>;
                  }
                });
              })}
            {!data || (data.length < 1 && <div className="flex-auto"></div>)}
            <div className={style1}>{e}</div>
          </div>
        );
      })}
      {clickedSession && (
        <SessionModal
          session={clickedSession}
          isOpen={sessionModel}
          closing={() => setSessionModel(false)}
        ></SessionModal>
      )}
    </div>
  );
};

export default TodaySessions;
