import Axios from "axios";
import { domain } from "./../global/domain";

export async function addSession(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/appointments/booking/one-one`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addSessionForGroupCoaching(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/appointments/booking/group`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteSessionFromGroupCoaching(id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/appointments/group/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
