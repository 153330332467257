import React, { useState, useEffect } from "react";
import ComingSession from "./content/dates/ComingSession";
import TodaySessions from "./content/dates/TodaySessions";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./content/dates/schedule.css";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { domain } from "../../../global/domain";
import { fetcher } from "../../../services/fetcher";
import Axios from "axios";

const Dates = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);

  const [data, setData] = useState(null);

  const formatedDate = (choosedDate) => {
    if (!choosedDate) {
      return;
    }
    const offset = choosedDate.getTimezoneOffset();
    const yourDate = new Date(choosedDate.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split("T")[0];
  };
  // md:h-150 md:overflow-y-auto
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">مواعيدي</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          جدول المواعيد
        </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 flex x-sm:flex-col-reverse x-sm:space-y-5 md:flex-row md:space-x-5">
        {date && data ? (
          <div className="bg-white x-sm:w-full md:w-3/5 rounded-xl solid-lines x-sm:mt-8 md:mt-0">
            <TodaySessions data={data}></TodaySessions>
          </div>
        ) : (
          <div className=" x-sm:w-full md:w-3/5 2xl:h-100 rounded-xl x-sm:mt-8 md:mt-0"></div>
        )}
        <div className="x-sm:w-full md:w-2/5 flex flex-col space-y-5">
          <div className="bg-white rounded-3xl">
            <div className="w-full x-sm:text-xs md:text-base xl:text-lg 2xl:text-3xl">
              <Calendar
                className="rounded-xl"
                minDate={new Date()}
                calendarType="Arabic"
                locale="ar-DZ"
                onChange={async (value) => {
                  setLoading(true);
                  setDate(value);
                  let res = await Axios.get(
                    `https://${domain}/appointments/table?date=${formatedDate(
                      value
                    )}`
                  );
                  setData(res.data);
                  setLoading(false);
                }}
                value={date}
              />
            </div>
          </div>
          <ComingSession></ComingSession>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Dates;
